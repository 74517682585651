// import React, { useState, useContext, useEffect } from 'react';
// import { Typography, Card, CardContent, CardMedia, Grid, Box, IconButton, Button } from '@mui/material';
// import { Link, useNavigate } from 'react-router-dom';
// import { Context as BlogContext } from "../../context/BlogContext";
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// const EventScreen = () => {
//     const navigate = useNavigate();
//     const [blogListData, setBlogListData] = useState([])
//     const { state: { BlogList, BlogListStatus, DeleteBlogStatus }, getAllBlogs, clearGetAllBlogsStatus, deleteBlog, clearDeleteBlogStatus } = useContext(BlogContext);

//     useEffect(() => {
//         getAllBlogs()
//     }, []);

//     useEffect(() => {
//         // if (BlogList) {
//         //     const sortedBlog = BlogList?.data?.slice().sort((a: _id, b: _id) => b._id - a._id);
//         //     setBlogListData(sortedBlog)
//         // }
//         if (BlogList) {
//             const sortedBlog = BlogList?.data?.slice().sort((a, b) =>
//                 new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
//             );
//             setBlogListData(sortedBlog);
//         }
//     }, [BlogList])

//     useEffect(() => {
//         if (DeleteBlogStatus !== "") {
//             if (DeleteBlogStatus === 200) {
//                 // setStatus(200)
//                 // setOpenAlert(true)
//                 clearDeleteBlogStatus();
//                 getAllBlogs();
//             } else {
//                 // setStatus(400)
//                 // setOpenAlert(true)
//             }
//         }
//     }, [DeleteBlogStatus]);


//     const handleDelete = (id) => {
//         console.log("delete customer id >", id)
//         deleteBlog(id);
//         // setOpenDelete(false);
//     };


//     const handleEdit = (post) => {
//         navigate('/blog/edit', { state: { post } });
//     };

//     return (
//         <div>
//             <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                 <Typography variant="h4" gutterBottom>
//                     Event
//                 </Typography>
//                 <Button variant="contained" color="primary"
//                     component={Link}
//                     to="/blog/create"
//                 >
//                     Create
//                 </Button>
//             </Box>
//             {!BlogList || BlogList?.data?.length === 0 ?
//                 <Typography variant="h6">No blog posts available.</Typography> :
//                 <Grid container spacing={4}>
//                     {blogListData?.map((post) => (
//                         <Grid item xs={12} key={post._id}>
//                             <Card>
//                                 <Box display="flex">
//                                     <Box display="flex" flex="1" alignItems="center">
//                                         <CardMedia
//                                             component="img"
//                                             sx={{ width: 270, height: 170, objectFit: 'cover' }}
//                                             image={post.image}
//                                             alt={post.title}
//                                         />
//                                         <Box ml={2}>
//                                             <Typography variant="h6" component="div">
//                                                 {post.topic}
//                                             </Typography>
//                                         </Box>
//                                     </Box>
//                                     <Box flexDirection="column" justifyContent="space-between">
//                                         <Box display="flex" justifyContent="flex-end" padding="8px">
//                                             <IconButton aria-label="edit" onClick={() => handleEdit(post)}>
//                                                 <EditIcon sx={{ color: 'black' }} />
//                                             </IconButton>
//                                             <IconButton aria-label="delete" onClick={() => handleDelete(post._id)}>
//                                                 <DeleteIcon sx={{ color: 'black' }} />
//                                             </IconButton>
//                                         </Box>
//                                     </Box>
//                                 </Box>
//                             </Card>
//                         </Grid>
//                     ))}
//                 </Grid>
//             }
//         </div >
//     );
// };

// export default EventScreen;


import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardContent, CardMedia, Grid, Box, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Context as EventContext } from "../../context/EventContext";
import { Context as BookingContext } from "../../context/BookingContext";


const RegisteredUsersScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state;
    

    const columns = [
        { field: '_id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'email', headerName: 'Email', width: 170 },
        { field: 'phone_number', headerName: 'Phone', width: 130 },
    ];

    const paginationModel = { page: 0, pageSize: 5 };

    return (

        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Registered Users
                </Typography>
                <Button variant="contained" color="primary"
                    component={Link}
                    to="/event"
                >
                    Back
                </Button>
            </Box>
            <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={data}
                    columns={columns}
                    getRowId={(row) => row._id}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    sx={{ border: 0 }}
                />
            </Paper>
        </div >
    );
}

export default RegisteredUsersScreen