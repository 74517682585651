import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const BlogReducer = (state, action) => {
    switch (action.type) {
        case "get_all_blogs":
            return { ...state, BlogList: action.payload };
        case "get_all_blogs_status":
            return { ...state, BlogListStatus: action.payload };
        case "clear_get_all_blogs_status":
            return { ...state, BlogListStatus: "" };

        case "create_blog":
            return { ...state, CreateBlog: action.payload };
        case "create_blog_status":
            return { ...state, CreateBlogStatus: action.payload };
        case "clear_create_blog_status":
            return { ...state, CreateBlogStatus: "" };

        case "delete_blog_status":
            return { ...state, DeleteBlogStatus: action.payload };
        case "clear_delete_blog_status":
            return { ...state, DeleteBlogStatus: "" };

        case "update_blog_status":
            return { ...state, UpdateBlogStatus: action.payload };
        case "clear_update_blog_status":
            return { ...state, UpdateBlogStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllBlogs = (dispatch) => async () => {
    try {
        const response = await listAPI.get('blog/getAll');
        dispatch({ type: "get_all_blogs", payload: response.data });
        dispatch({ type: "get_all_blogs_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_blogs_status", payload: 400 });
    }
};

const clearGetAllBlogsStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_blogs_status" });
};


/** Create Blog **/
const createBlog = dispatch => async ({ topic, description, image }) => {
    try {
        const form = new FormData();

        form.append('topic', topic);
        form.append('description', description);
        form.append('images', image);

        const response = await listAPI.post("blog/add", form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "create_blog", payload: response.data });
        dispatch({ type: "create_blog_status", payload: response.status });
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "create_blog_status", payload: 400 })

    }
}

const clearCreateBlogStatus = dispatch => () => {
    dispatch({ type: "clear_create_blog_status" });
};

/** Update Blog **/
const updateBlog = dispatch => async ({ blogId, topic, description, image }) => {
    try {
        const form = new FormData();

        form.append('topic', topic);
        form.append('description', description);
        form.append('images', image);

        const response = await listAPI.put("/blog/update/" + blogId, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "update_blog", payload: response.data });
        dispatch({ type: "update_blog_status", payload: response.status });
        console.log("create_blog", response);
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "update_blog_status", payload: 400 })
    }
}


const clearUpdateBlogStatus = dispatch => () => {
    dispatch({ type: "clear_update_blog_status" });
};


/** Delete blog **/
const deleteBlog = (dispatch) => async (blogId) => {
    try {
        const response = await listAPI.delete("/blog/delete/" + blogId);
        dispatch({ type: "delete_blog_status", payload: response.status });
        console.log("test", response)
    } catch (e) {
        console.log(e);
        dispatch({ type: "delete_blog_status", payload: 400 });
    }
};
const clearDeleteBlogStatus = (dispatch) => () => {
    dispatch({ type: "clear_delete_blog_status" });
};


export const { Provider, Context } = CreateDataContext(
    BlogReducer,
    {
        getAllBlogs, clearGetAllBlogsStatus,
        createBlog, clearCreateBlogStatus,
        deleteBlog, clearDeleteBlogStatus,
        updateBlog, clearUpdateBlogStatus
    },
    {
        BlogList: [],
        BlogListStatus: "",
        CreateBlog: [],
        CreateBlogStatus: "",
        DeleteBlogStatus: "",
        UpdateBlogStatus: ""
    }
);
