import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardContent, CardMedia, Grid, Box, IconButton, Button, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import { Context as EventContext } from "../../context/EventContext";
import { Context as BookingContext } from "../../context/BookingContext";


const EventScreen = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([])
    const [open, setOpen] = useState(false);
    const { state: { EventList, EventListStatus, DeleteEventStatus }, getAllEvents, clearGetAllEventsStatus, deleteEvent, clearDeleteEventStatus, } = useContext(EventContext);
    const { state: { BookingList, BookingListStatus }, getAllBookings, clearGetAllBookingsStatus, } = useContext(BookingContext);

    useEffect(() => {
        getAllEvents()
        getAllBookings()
    }, []);
    

    useEffect(() => {
        const combinedData = EventList?.data?.map((event) => {
            // Find users who registered for this event
            const usersForEvent = BookingList?.data?.filter(
                (user) => user.event?._id === event._id
            );

            // Return the event with a new `registeredUsers` property
            return {
                ...event,
                registeredUsers: usersForEvent,
            };
        });
        setAllData(combinedData)
    }, [EventList,BookingList])

    useEffect(() => {
        if (DeleteEventStatus !== "") {
            if (DeleteEventStatus === 200) {
                setOpen(true)
                clearDeleteEventStatus();
                getAllEvents()
                getAllBookings()
            } else {
            }
        }
    }, [DeleteEventStatus]);


    const handleViewUsers = (data) => {
        navigate('/event/registered-users', { state: { data } });
    };

    const handleEdit = (data) => {
        navigate('/event/edit', { state: { data } });
    };

    const handleDelete = (id) => {
        deleteEvent(id);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const columns = [
        { field: '_id', headerName: 'ID', width: 70 },
        { field: 'event_name', headerName: 'Event name', width: 130 },
        { field: 'event_date', headerName: 'Date', width: 130 },
        { field: 'event_time', headerName: 'Time', width: 130 },
        {
            field: 'event_description',
            headerName: 'Description',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
        },
        {
            field: 'user_count',
            headerName: 'User Count',
            width: 120,
            renderCell: (params) => <span>{params.row.registeredUsers?.length || 0}</span>,
        },
        {
            field: 'view_users',
            headerName: 'View Users',
            width: 150,
            sortable: false,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleViewUsers(params.row.registeredUsers)}
                >
                    View Users
                </Button>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton
                        color="primary"
                        onClick={() => handleEdit(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={() => handleDelete(params.row._id)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ];


    const paginationModel = { page: 0, pageSize: 5 };

    return (

        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Event
                </Typography>
                <Button variant="contained" color="primary"
                    component={Link}
                    to="/event/create"
                >
                    Create
                </Button>
            </Box>
            <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={allData}
                    columns={columns}
                    getRowId={(row) => row._id}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    sx={{ border: 0 }}
                />
            </Paper>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Delete success!
                </Alert>
            </Snackbar>
        </div >
    );
}

export default EventScreen