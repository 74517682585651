import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const InstaReducer = (state, action) => {
    switch (action.type) {
        case "get_all_insta":
            return { ...state, InstaList: action.payload };
        case "get_all_insta_status":
            return { ...state, InstaListStatus: action.payload };
        case "clear_get_all_insta_status":
            return { ...state, InstaListStatus: "" };

        case "create_insta":
            return { ...state, CreateInsta: action.payload };
        case "create_insta_status":
            return { ...state, CreateInstaStatus: action.payload };
        case "clear_create_insta_status":
            return { ...state, CreateInstaStatus: "" };

        case "delete_event_status":
            return { ...state, DeleteEventStatus: action.payload };
        case "clear_delete_event_status":
            return { ...state, DeleteEventStatus: "" };

        case "update_event_status":
            return { ...state, UpdateEventStatus: action.payload };
        case "clear_update_event_status":
            return { ...state, UpdateEventStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllInsta = (dispatch) => async () => {
    try {
        const response = await listAPI.get('/insta/getAllInsta');
        dispatch({ type: "get_all_insta", payload: response.data });
        dispatch({ type: "get_all_insta_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_insta_status", payload: 400 });
    }
};

const clearGetAllInstaStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_insta_status" });
};


/** Create insta **/
const createInstaPost = dispatch => async ({ insta_link, image }) => {
    try {
        const form = new FormData();

        form.append('text', insta_link);
        form.append('images', image);

        const response = await listAPI.post("insta/add", form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "create_insta", payload: response.data });
        dispatch({ type: "create_insta_status", payload: response.status });
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "create_insta_status", payload: 400 })

    }
}

const clearCreateInstaStatus = dispatch => () => {
    dispatch({ type: "clear_create_insta_status" });
};

/** Update event **/
const updateEvents = dispatch => async ({ eventId, event_name, event_date, event_time, event_description, image }) => {
    try {
        const form = new FormData();

        form.append('event_name', event_name);
        form.append('event_time', event_time);
        form.append('event_description', event_description);
        form.append('event_date', event_date);
        form.append('images', image);

        const response = await listAPI.put("/event/update/" + eventId, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "update_event", payload: response.data });
        dispatch({ type: "update_event_status", payload: response.status });
        console.log("create_event", response);
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "update_event_status", payload: 400 })
    }
}


const clearUpdateEventStatus = dispatch => () => {
    dispatch({ type: "clear_update_event_status" });
};


/** Delete event **/
const deleteEvent = (dispatch) => async (eventId) => {
    try {
        const response = await listAPI.delete("/event/delete/" + eventId);
        dispatch({ type: "delete_event_status", payload: response.status });
        console.log("test", response)
    } catch (e) {
        console.log(e);
        dispatch({ type: "delete_event_status", payload: 400 });
    }
};
const clearDeleteEventStatus = (dispatch) => () => {
    dispatch({ type: "clear_delete_event_status" });
};


export const { Provider, Context } = CreateDataContext(
    InstaReducer,
    {
        getAllInsta, clearGetAllInstaStatus,
        createInstaPost, clearCreateInstaStatus,
        // deleteEvent, clearDeleteEventStatus,
        // updateEvents, clearUpdateEventStatus
    },
    {
        InstaList: [],
        InstaListStatus: "",
        CreateInsta: [],
        CreateInstaStatus: "",
        // DeleteEventStatus: "",
        // UpdateEventStatus: ""
    }
);
