import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const EventReducer = (state, action) => {
    switch (action.type) {
        case "get_all_events":
            return { ...state, EventList: action.payload };
        case "get_all_events_status":
            return { ...state, EventListStatus: action.payload };
        case "clear_get_all_events_status":
            return { ...state, EventListStatus: "" };

        case "create_event":
            return { ...state, CreateEvent: action.payload };
        case "create_event_status":
            return { ...state, CreateEventStatus: action.payload };
        case "clear_create_event_status":
            return { ...state, CreateEventStatus: "" };

        case "delete_event_status":
            return { ...state, DeleteEventStatus: action.payload };
        case "clear_delete_event_status":
            return { ...state, DeleteEventStatus: "" };

        case "update_event_status":
            return { ...state, UpdateEventStatus: action.payload };
        case "clear_update_event_status":
            return { ...state, UpdateEventStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllEvents = (dispatch) => async () => {
    try {
        const response = await listAPI.get('event/getAll');
        dispatch({ type: "get_all_events", payload: response.data });
        dispatch({ type: "get_all_events_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_events_status", payload: 400 });
    }
};

const clearGetAllEventsStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_events_status" });
};


/** Create Blog **/
const createEvent = dispatch => async ({ event_name, event_description, event_date, image, event_time }) => {
    try {
        const form = new FormData();

        form.append('event_name', event_name);
        form.append('event_time', event_time);
        form.append('event_description', event_description);
        form.append('event_date', event_date);
        form.append('images', image);

        const response = await listAPI.post("event/add", form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "create_event", payload: response.data });
        dispatch({ type: "create_event_status", payload: response.status });
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "create_event_status", payload: 400 })

    }
}

const clearCreateEventStatus = dispatch => () => {
    dispatch({ type: "clear_create_event_status" });
};

/** Update event **/
const updateEvents = dispatch => async ({ eventId, event_name, event_date, event_time, event_description, image }) => {
    try {
        const form = new FormData();

        form.append('event_name', event_name);
        form.append('event_time', event_time);
        form.append('event_description', event_description);
        form.append('event_date', event_date);
        form.append('images', image);

        const response = await listAPI.put("/event/update/" + eventId, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "update_event", payload: response.data });
        dispatch({ type: "update_event_status", payload: response.status });
        console.log("create_event", response);
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "update_event_status", payload: 400 })
    }
}


const clearUpdateEventStatus = dispatch => () => {
    dispatch({ type: "clear_update_event_status" });
};


/** Delete event **/
const deleteEvent = (dispatch) => async (eventId) => {
    try {
        const response = await listAPI.delete("/event/delete/" + eventId);
        dispatch({ type: "delete_event_status", payload: response.status });
        console.log("test", response)
    } catch (e) {
        console.log(e);
        dispatch({ type: "delete_event_status", payload: 400 });
    }
};
const clearDeleteEventStatus = (dispatch) => () => {
    dispatch({ type: "clear_delete_event_status" });
};


export const { Provider, Context } = CreateDataContext(
    EventReducer,
    {
        getAllEvents, clearGetAllEventsStatus,
        createEvent, clearCreateEventStatus,
        deleteEvent, clearDeleteEventStatus,
        updateEvents, clearUpdateEventStatus
    },
    {
        EventList: [],
        EventListStatus: "",
        CreateEvent: [],
        CreateEventStatus: "",
        DeleteEventStatus: "",
        UpdateEventStatus: ""
    }
);
