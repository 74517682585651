import React, { useState, useEffect, useContext, useRef } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromHTML, ContentState, } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Box, Button, IconButton, Divider, Typography, TextField } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import { useNavigate } from 'react-router-dom';
import { Context as BlogContext } from "../../context/BlogContext";
import draftToHtml from 'draftjs-to-html';
import { useLocation } from 'react-router-dom';


const EditBlog = () => {
    const editorRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { post } = location.state;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [topic, setTopic] = useState(post?.topic)
    const [description, setDescrition] = useState('')
    const [image, setImage] = useState(post?.image);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const { state: { UpdateBlogStatus }, updateBlog, clearUpdateBlogStatus } = useContext(BlogContext);


    useEffect(() => {
        if (post) {
            const blocksFromHTML = convertFromHTML(post?.description);
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [post]);


    const handleKeyCommand = (command) => {
        // const newState = EditorState.handleKeyCommand(state, command);
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const toggleInlineStyle = (style) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const getEditorContentAsHtml = () => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        return draftToHtml(rawContentState);
    };

    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            setImage(imageFile);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const descriptionss = getEditorContentAsHtml();

        try {
            updateBlog({
                blogId: post?._id,
                topic: topic,
                description: descriptionss,
                // image: image,
            });

        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleClick = (newState) => () => {
        setState({ ...newState, open: true });
    };


    useEffect(() => {
        if (UpdateBlogStatus === 200) {
            // console.log("success")
            handleClick()
            clearUpdateBlogStatus();
            navigate('/blog');
            // const timeoutId = setTimeout(() => {
            //     clearCreateBlogStatus();
            //     navigate('/blog');
            // }, 2000);

        } else if (UpdateBlogStatus === 400 || UpdateBlogStatus === 404) {
            clearUpdateBlogStatus();
        }
    }, [UpdateBlogStatus]);


    return (
        <Box component="form"
            onSubmit={handleSubmit}
            sx={{ mx: 'auto', p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Update Blog
            </Typography>

            <Box>
                <Typography variant="subtitle1" gutterBottom>
                    Topic
                </Typography>
                <TextField
                    label="Topic"
                    variant="outlined"
                    fullWidth
                    name="topic"
                    value={topic}
                    onChange={(event) => setTopic(event.target.value)}
                    // margin="normal"
                    required
                />
            </Box>


            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
                Description
            </Typography>
            <Box
                sx={{
                    padding: 2,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    // maxWidth: '600px',
                    margin: 'auto',
                    mt: 1,
                }}
            >
                <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                    <IconButton onClick={() => toggleInlineStyle('BOLD')}>
                        <FormatBoldIcon />
                    </IconButton>
                    <IconButton onClick={() => toggleInlineStyle('ITALIC')}>
                        <FormatItalicIcon />
                    </IconButton>
                    <IconButton onClick={() => toggleInlineStyle('UNDERLINE')}>
                        <FormatUnderlinedIcon />
                    </IconButton>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box
                    sx={{
                        minHeight: '200px',
                        padding: 1,
                        border: '1px solid #ddd',
                        borderRadius: 1,
                    }}
                // onClick={() => editorRef.current.focus()}
                >
                    <Editor
                        ref={editorRef}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        onChange={setEditorState}
                        placeholder="Write something here..."
                    />
                </Box>
            </Box>

            <Box mt={2} mb={2}>
                <Typography variant="subtitle1" gutterBottom>
                    Upload Image
                </Typography>
                <input
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                />
            </Box>

            <Box mt={3}>
                <Button
                    // onClick={() => handleSubmit()}
                    type="submit" variant="contained" color="primary" fullWidth>
                    Update Blog
                </Button>
            </Box>


        </Box>
    );
};

export default EditBlog;
