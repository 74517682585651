import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Divider, Typography, TextField, TextareaAutosize } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useNavigate } from 'react-router-dom';
import { Context as InstaContext } from "../../context/InstaContext";


const CreateInsta = () => {
    const navigate = useNavigate();
    const [topic, setTopic] = useState('')
    const [description, setDescrition] = useState('')
    const [image, setImage] = useState("");
    const [value, setValue] = useState(dayjs());
    const [timeValue, setTimeValue] = useState(null);
    const [timeValueFormatted, setTimeValueFormatted] = useState(null);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const { state: { CreateInstaStatus }, createInstaPost, clearCreateInstaStatus, } = useContext(InstaContext);


    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            setImage(imageFile);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            createInstaPost({
                insta_link: topic,
                image: image
            });

        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleClick = (newState) => () => {
        setState({ ...newState, open: true });
    };


    useEffect(() => {
        if (CreateInstaStatus === 201) {
            console.log("success")
            handleClick()
            clearCreateInstaStatus();
            navigate('/instagram-post');
            // const timeoutId = setTimeout(() => {
            //     clearCreateBlogStatus();
            //     navigate('/blog');
            // }, 2000);
        } else if (CreateInstaStatus === 400 || CreateInstaStatus === 404) {
            clearCreateInstaStatus();
        }
    }, [CreateInstaStatus]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box component="form"
                onSubmit={handleSubmit}
                sx={{ mx: 'auto', p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Create Post
                </Typography>

                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Instagram post link
                    </Typography>
                    <TextField
                        label="Instagram post link"
                        variant="outlined"
                        fullWidth
                        name="Instagram post link"
                        value={topic}
                        onChange={(event) => setTopic(event.target.value)}
                        // margin="normal"
                        required
                    />
                </Box>

                {/* <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Date
                    </Typography>
                    <DatePicker label="Date picker"
                        value={value}
                        onChange={(newValue) => setValue(newValue)} />
                </Box> */}

                <Box mt={2} mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        Upload Image
                    </Typography>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        required
                    />
                </Box>

                <Box mt={3}>
                    <Button
                        // onClick={() => handleSubmit()}
                        type="submit" variant="contained" color="primary" fullWidth>
                        Create Instagram Post
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default CreateInsta;
