// // import React, { useState } from 'react';
// // import {
// //     AppBar,
// //     Toolbar,
// //     IconButton,
// //     Typography,
// //     Drawer,
// //     List,
// //     ListItem,
// //     ListItemIcon,
// //     ListItemText,
// //     CssBaseline,
// //     Divider,
// //     Box
// // } from '@mui/material';
// // import MenuIcon from '@mui/icons-material/Menu';
// // import HomeIcon from '@mui/icons-material/Home';
// // import DashboardIcon from '@mui/icons-material/Dashboard';
// // import SettingsIcon from '@mui/icons-material/Settings';

// // const drawerWidth = 240;

// // const Dashboard = () => {
// //     const [mobileOpen, setMobileOpen] = useState(false);

// //     const handleDrawerToggle = () => {
// //         setMobileOpen(!mobileOpen);
// //     };

// //     const drawer = (
// //         <div>
// //             <Toolbar />
// //             <Divider />
// //             <List>
// //                 {['Home', 'Dashboard', 'Settings'].map((text, index) => (
// //                     <ListItem button key={text}>
// //                         <ListItemIcon>
// //                             {index === 0 ? <HomeIcon /> : index === 1 ? <DashboardIcon /> : <SettingsIcon />}
// //                         </ListItemIcon>
// //                         <ListItemText primary={text} />
// //                     </ListItem>
// //                 ))}
// //             </List>
// //         </div>
// //     );

// //     return (
// //         <Box sx={{ display: 'flex' }}>
// //             <CssBaseline />
// //             <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
// //                 <Toolbar>
// //                     <IconButton
// //                         color="inherit"
// //                         aria-label="open drawer"
// //                         edge="start"
// //                         onClick={handleDrawerToggle}
// //                         sx={{ mr: 2, display: { sm: 'none' } }}
// //                     >
// //                         <MenuIcon />
// //                     </IconButton>
// //                     <Typography variant="h6" noWrap component="div">
// //                         Dashboard
// //                     </Typography>
// //                 </Toolbar>
// //             </AppBar>
// //             <Box
// //                 component="nav"
// //                 sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
// //                 aria-label="dashboard folders"
// //             >
// //                 {/* Drawer for mobile */}
// //                 <Drawer
// //                     variant="temporary"
// //                     open={mobileOpen}
// //                     onClose={handleDrawerToggle}
// //                     ModalProps={{
// //                         keepMounted: true, // Better open performance on mobile.
// //                     }}
// //                     sx={{
// //                         display: { xs: 'block', sm: 'none' },
// //                         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
// //                     }}
// //                 >
// //                     {drawer}
// //                 </Drawer>
// //                 {/* Drawer for desktop */}
// //                 <Drawer
// //                     variant="permanent"
// //                     sx={{
// //                         display: { xs: 'none', sm: 'block' },
// //                         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
// //                     }}
// //                     open
// //                 >
// //                     {drawer}
// //                 </Drawer>
// //             </Box>
// //             <Box
// //                 component="main"
// //                 sx={{
// //                     flexGrow: 1,
// //                     p: 3,
// //                     width: { sm: `calc(100% - ${drawerWidth}px)` },
// //                 }}
// //             >
// //                 <Toolbar />
// //                 <Typography paragraph>
// //                     Welcome to the dashboard!
// //                 </Typography>
// //             </Box>
// //         </Box>
// //     );
// // };

// // export default Dashboard;

// import React, { useState } from 'react';
// import {
//     AppBar,
//     Toolbar,
//     IconButton,
//     Typography,
//     Drawer,
//     List,
//     ListItem,
//     ListItemIcon,
//     ListItemText,
//     CssBaseline,
//     Divider,
//     Box
// } from '@mui/material';
// import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
// import Colors from '../assets/color'
// import MenuIcon from '@mui/icons-material/Menu';
// import HomeIcon from '@mui/icons-material/Home';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import SettingsIcon from '@mui/icons-material/Settings';
// import logo from '../assets/images/logo.png'
// import BlogScreen from './Blog/BlogScreen';
// import ArticleIcon from '@mui/icons-material/Article';

// const drawerWidth = 240;

// const Dashboard = () => {
//     const [mobileOpen, setMobileOpen] = useState(false);
//     const navigate = useNavigate();
//     const handleDrawerToggle = () => {
//         setMobileOpen(!mobileOpen);
//     };

//     // const drawer = (
//     //     <div>
//     //         <Toolbar />
//     //         <Divider />
//     //         <List>
//     //             {['Home', 'Dashboard', 'Blog', 'Settings'].map((text, index) => (
//     //                 <ListItem button key={text}>
//     //                     <ListItemIcon>
//     //                         {index === 0 ? <HomeIcon />
//     //                             : index === 1 ? <DashboardIcon />
//     //                                 : index === 2 ? <DashboardIcon />
//     //                                     : <SettingsIcon />}
//     //                     </ListItemIcon>
//     //                     <ListItemText primary={text} />
//     //                 </ListItem>
//     //             ))}
//     //         </List>
//     //     </div>
//     // );

//     const drawer = (
//         <div>
//             <Toolbar />
//             <Divider />
//             <List>
//                 <ListItem button onClick={() => navigate('/')}>
//                     <ListItemIcon><HomeIcon /></ListItemIcon>
//                     <ListItemText primary="Home" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/dashboard')}>
//                     <ListItemIcon><DashboardIcon /></ListItemIcon>
//                     <ListItemText primary="Dashboard" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/dashboard/blog')}>
//                     <ListItemIcon><ArticleIcon /></ListItemIcon>
//                     <ListItemText primary="Blog" />
//                 </ListItem>
//                 <ListItem button onClick={() => navigate('/settings')}>
//                     <ListItemIcon><SettingsIcon /></ListItemIcon>
//                     <ListItemText primary="Settings" />
//                 </ListItem>
//             </List>
//         </div>
//     );

//     return (
//         <Box sx={{ display: 'flex' }}>
//             <CssBaseline />
//             <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: Colors.background }}>
//                 <Toolbar>
//                     <IconButton
//                         color="inherit"
//                         aria-label="open drawer"
//                         edge="start"
//                         onClick={handleDrawerToggle}
//                         sx={{ mr: 2, display: { sm: 'none' } }}
//                     >
//                         <MenuIcon />
//                     </IconButton>
//                     <img src={logo} alt="Logo" style={{ height: 40, marginRight: 8 }} />
//                     <Typography variant="h6" noWrap component="div" sx={{ color: Colors.black }}>
//                         Social Paws
//                     </Typography>
//                 </Toolbar>
//             </AppBar>
//             <Box
//                 component="nav"
//                 sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
//                 aria-label="dashboard folders"
//             >
//                 {/* Drawer for mobile */}
//                 <Drawer
//                     variant="temporary"
//                     open={mobileOpen}
//                     onClose={handleDrawerToggle}
//                     ModalProps={{
//                         keepMounted: true, // Better open performance on mobile.
//                     }}
//                     sx={{
//                         display: { xs: 'block', sm: 'none' },
//                         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//                     }}
//                 >
//                     {drawer}
//                 </Drawer>
//                 {/* Drawer for desktop */}
//                 <Drawer
//                     variant="permanent"
//                     sx={{
//                         display: { xs: 'none', sm: 'block' },
//                         '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
//                     }}
//                     open
//                 >
//                     {drawer}
//                 </Drawer>
//             </Box>
//             <Box
//                 component="main"
//                 sx={{
//                     flexGrow: 1,
//                     p: 3,
//                     width: { sm: `calc(100% - ${drawerWidth}px)` },
//                 }}
//             >
//                 <Toolbar />
//                 <Typography paragraph>
//                     Welcome to the dashboard!
//                 </Typography>

//                 <Routes>
//                     {/* <Route path="/" element={<HomeScreen />} />
//                     <Route path="/dashboard" element={<DashboardScreen />} /> */}
//                     <Route path="/dashboard/blog" element={<BlogScreen />} />
//                     {/* <Route path="/settings" element={<SettingsScreen />} /> */}
//                 </Routes>
//             </Box>
//         </Box>
//     );
// };

// export default Dashboard;


import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    CssBaseline,
    Divider,
    Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import EventIcon from '@mui/icons-material/Event';
import InstagramIcon from '@mui/icons-material/Instagram';
import { BrowserRouter as Router, Routes, Route, useNavigate, Outlet } from 'react-router-dom';
import BlogScreen from './Blog/BlogScreen';
import logo from '../assets/images/logo.png'
import Colors from '../assets/color'
import CreateBlogScreen from './Blog/CreateBlog';
import EditBlog from './Blog/EditBlog';
import EventScreen from './Event/EventScreen';
import CreateEvent from './Event/CreateEvent';
import EditEvent from './Event/EditEvent';
import RegisteredUsersScreen from './Booking/RegisteredUsersScreen';
import CreateInsta from './insta/CreateInsta';
import InstagramScreen from './insta/InstagramScreen';

const drawerWidth = 240;

const Dashboard = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                <ListItem button onClick={() => navigate('/blog')}>
                    <ListItemIcon><HomeIcon /></ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => navigate('/blog')}>
                    <ListItemIcon><ArticleIcon /></ListItemIcon>
                    <ListItemText primary="Blog" />
                </ListItem>
                <ListItem button onClick={() => navigate('/event')}>
                    <ListItemIcon><EventIcon /></ListItemIcon>
                    <ListItemText primary="Event" />
                </ListItem>
                <ListItem button onClick={() => navigate('/instagram-post')}>
                    <ListItemIcon><InstagramIcon /></ListItemIcon>
                    <ListItemText primary="SocialMedia Post" />
                </ListItem>
            </List>
        </div>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: Colors.background }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={logo} alt="Logo" style={{ height: 40, marginRight: 8 }} />
                    <Typography variant="h6" noWrap component="div" sx={{ color: Colors.black }}>
                        Social Paws
                    </Typography>
                </Toolbar>
            </AppBar>


            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="dashboard folders"
            >
                {/* Drawer for mobile */}
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                {/* Drawer for desktop */}
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Toolbar />
                <Routes>
                    <Route path="/blog" element={<BlogScreen />} />
                    <Route path="/blog/create" element={<CreateBlogScreen />} />
                    <Route path="/blog/edit" element={<EditBlog />} />
                    <Route path="/event" element={<EventScreen />} />
                    <Route path="/event/create" element={<CreateEvent />} />
                    <Route path="/event/edit" element={<EditEvent />} />
                    <Route path="/event/registered-users" element={<RegisteredUsersScreen />} />
                    <Route path="/instagram-post" element={<InstagramScreen />} />
                    <Route path="/instagram-post/create" element={<CreateInsta />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default Dashboard;

