import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Divider, Typography, TextField, TextareaAutosize } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context as EventContext } from "../../context/EventContext";


const EditEvent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = location.state;
    const [topic, setTopic] = useState(data?.event_name)
    const [description, setDescrition] = useState(data?.event_description)
    const [image, setImage] = useState("");
    const [value, setValue] = useState(dayjs(data?.event_date));
    const [timeValue, setTimeValue] = useState(dayjs(data?.event_time, 'hh:mm A')); 
    const [timeValueFormatted, setTimeValueFormatted] = useState(data?.event_time);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const { state: { UpdateEventStatus },  updateEvents, clearUpdateEventStatus } = useContext(EventContext);


    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            setImage(imageFile);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            updateEvents({
                event_name: topic,
                event_description: description,
                event_date: value.format('YYYY-MM-DD'),
                event_time: timeValueFormatted,
                image: image,
                eventId:data?._id
            });

        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleClick = (newState) => () => {
        setState({ ...newState, open: true });
    };


    useEffect(() => {
        if (UpdateEventStatus === 200) {
            console.log("success")
            handleClick()
            clearUpdateEventStatus();
            navigate('/event');
            // const timeoutId = setTimeout(() => {
            //     clearCreateBlogStatus();
            //     navigate('/blog');
            // }, 2000);
        } else if (UpdateEventStatus === 400 || UpdateEventStatus === 404) {
            clearUpdateEventStatus();
        }
    }, [UpdateEventStatus]);


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box component="form"
                onSubmit={handleSubmit}
                sx={{ mx: 'auto', p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Update Event
                </Typography>

                <Box>
                    <Typography variant="subtitle1" gutterBottom>
                        Event Name
                    </Typography>
                    <TextField
                        label="event name"
                        variant="outlined"
                        fullWidth
                        name="event name"
                        value={topic}
                        onChange={(event) => setTopic(event.target.value)}
                        // margin="normal"
                        required
                    />
                </Box>

                <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Description
                    </Typography>
                    <TextareaAutosize
                        minRows={4}
                        placeholder="Enter description here"
                        style={{
                            width: '100%',
                            padding: '8px',
                            borderRadius: '4px',
                            borderColor: '#ccc',
                            fontFamily: 'inherit',
                            fontSize: 'inherit',
                        }}
                        value={description}
                        onChange={(event) => setDescrition(event.target.value)}
                        required
                    />
                </Box>

                <Box sx={{ mt: 1, display: 'flex', gap: 4 }}>
                    {/* Date Picker Section */}
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            Date
                        </Typography>
                        <DatePicker
                            label="Date picker"
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                            required
                        />
                    </Box>

                    {/* Time Picker Section */}
                    <Box>
                        <Typography variant="subtitle1" gutterBottom>
                            Time
                        </Typography>
                        <TimeField
                            label="Time field"
                            value={timeValue}
                            onChange={(newTime) => {
                                setTimeValue(newTime)

                                const hours = newTime?.$H; 
                                const minutes = newTime?.$m; 
                                const ampm = hours >= 12 ? 'PM' : 'AM';
                                const formattedHours = hours % 12 || 12; 
                                const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;

                                setTimeValueFormatted(formattedTime)
                            }}
                            required />
                    </Box>
                </Box>

                <Box mt={2} mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        Upload Image
                    </Typography>
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        required
                    />
                </Box>

                <Box mt={3}>
                    <Button
                        // onClick={() => handleSubmit()}
                        type="submit" variant="contained" color="primary" fullWidth>
                        Update Event
                    </Button>
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default EditEvent;
