import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button, Box, Typography, Container, CssBaseline, TextField, Snackbar } from '@mui/material';
import logo from '../assets/images/logo.png'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    container: {
        backgroundColor: '#e9f0f6',
        borderRadius: 20,
        height: '500px',
        [theme.breakpoints.down('sm')]: {
            width: '75%',
            marginTop: 80,
            backgroundColor: '#e9f0f6',
        },
    },
    image: {
        margin: theme.spacing(1),
        width: 100,
        height: 100
    },
    form: {
        width: '80%',
        marginTop: theme.spacing(1),
    },
    button_login: {
        background: 'linear-gradient(45deg, #000000 30%, #3A3B3F 90%)',
        border: 0,
        borderRadius: 3,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        color: 'white',
        height: 42,
        padding: '0 30px',
        marginTop: '4%'
    },
    snack: {
        color: 'red'
    }
}));


const Login = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [open, setOpen] = useState(false);


    const handleLogin = () => {
        if (userName === "admin@gmail.com" && password === "admin") {
            navigate('/blog');
        } else if (userName === "" && password === "" || userName === "admin" && password === "" || userName === "" && password === "admin") {
            // setErrorMessage(100);
            // handleClickCreateCustomerSuccess();
        } else {
            setErrorMessage(101);
            // handleClickCreateCustomerSuccess();
        }
        // loginUser({ email: userName, password: password })
    }

   
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    return (
        <Container component="main" maxWidth="xs" className={classes.container} >
            <CssBaseline />
            <div className={classes.paper}>
                <img src={logo} className={classes.image} alt="logo" />
                <Typography component="h3" variant="h7">
                    Welcome to Socail Paws
                </Typography>
                <div className={classes.form}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="user_name"
                        label="User Name"
                        autoComplete="user name"
                        autoFocus
                        onChange={e => setUserName(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        autoFocus
                        onChange={e => setPassword(e.target.value)}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        className={classes.button_login}
                        onClick={() => handleLogin()}
                    >
                        Sign In
                    </Button>
                </div>
            </div>
            <Box mt={8}>
                <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '-40px' }}>
                    All Right Reserved.
                </Typography>
            </Box>

        </Container>
    );
}

export default Login;
