import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const BookingReducer = (state, action) => {
    switch (action.type) {
        case "get_all_bookings":
            return { ...state, BookingList: action.payload };
        case "get_all_bookings_status":
            return { ...state, BookingListStatus: action.payload };
        case "clear_get_all_bookings_status":
            return { ...state, bookingListStatus: "" };
        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllBookings = (dispatch) => async () => {
    try {
        const response = await listAPI.get('booking/getAll');
        dispatch({ type: "get_all_bookings", payload: response.data });
        dispatch({ type: "get_all_bookings_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_bookings_status", payload: 400 });
    }
};

const clearGetAllBookingsStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_bookings_status" });
};


export const { Provider, Context } = CreateDataContext(
    BookingReducer,
    {
        getAllBookings, clearGetAllBookingsStatus,
        // createEvent, clearCreateEventStatus,
        // deleteBlog, clearDeleteBlogStatus,
        // updateBlog, clearUpdateBlogStatus
    },
    {
        BookingList: [],
        BookingListStatus: "",
        // CreateEvent: [],
        // CreateEventStatus: "",
        // DeleteBlogStatus: "",
        // UpdateBlogStatus: ""
    }
);
